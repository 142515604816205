import React from "react";
import Layout from "../../components/Layout";
import Seo from "../../components/seo";

const DESCRIPTION = "Balance Banter Podcast";

function EmbedPodcastPlayer() {
  return (
    <div className="embed-podcast-player">
      <iframe
        id="podcast_player_iframe"
        src="https://www.buzzsprout.com/2062689?artist=&client_source=large_player&iframe=true&referrer=https%3A%2F%2Fwww.buzzsprout.com%2F2062689%2Fpodcast%2Fembed"
        loading="lazy"
        width="100%"
        height="100vh"
        frameborder="0"
        scrolling="no"
        title="#Balance Banter"
      ></iframe>
    </div>
  );
}

function BalanceBanterPodcast() {
  return (
    <div className="balance-boot-camp balance-podcast">
      <Seo title="Balance Banter Podcast" description={DESCRIPTION} />
      <h1 className="header-title header has-text-centered is-size-1 has-text-weight-bold">
        #BALANCE Banter Podcast
      </h1>

      <EmbedPodcastPlayer />
    </div>
  );
}

const BalanceBanterPodcastPage = () => (
  <Layout>
    <BalanceBanterPodcast />
  </Layout>
);

export default BalanceBanterPodcastPage;
